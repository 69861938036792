@import url("helpers/colors.css");

.WebsiteLanguageSwitcher {
  display: flex;
  flex-direction: column;
  z-index: 4;
  width: fit-content;
}

.dropdown,
.websiteLangButton {
  font-size: 1.5rem;
  background-color: transparent;
  border: none;
  color: #b9c5d8;
}

.langIcon {
  font-size: 1.5rem;
}

.dropdown:hover,
.websiteLangButton:hover {
  cursor: pointer;
}

.dropdown {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 0.8rem;
}

.triangle {
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  height: 3rem;
  width: 3rem;
  right: 5.3rem;
  background-color: #153c7d;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: #153c7d;
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
}

.websiteLangButton {
  padding: 0.4rem 0.6rem;
}

.websiteLangButton:hover {
  background-color: #2c508a;
  border-radius: 20px;
}

.selected {
  color: #fff;
}

.hide {
  display: none;
}

.show {
  display: block !important;
}

.trianglePa {
  position: absolute;
  top: 3.7rem;
  right: 4.3rem;
}

.dropdownContentPa {
  position: absolute;
  top: 5rem;
  right: 4.3rem;
}


@media (max-width: 1540px) {
  .pr {
    position: relative;
  }
  .pa {
    position: absolute;
  }
  .dropdownContentPa {
    top: 3.7rem;
    right: 1.5rem;
  }
  .trianglePa {
    top: 2.5rem;
    right: 1.5rem;
  }
  .dropdownMenu {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -110%);
  }
  .triangleMenu {
    position: absolute;
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}


@media (max-width: 550px) {
  .dropdown, .websiteLangButton, .langIcon {
    font-size: 1.5rem;
  }
  .dropdownContent {
    padding: 0.1rem 0.2rem;
  }
}
@media (max-height: 690px) and (max-width: 550px) {
  .dropdown, .websiteLangButton, .langIcon {
    font-size: 1rem;
  }
}

@media (max-width: 375px) {
  .dropdown, .websiteLangButton, .dropdown_lang_text {
    font-size: 1rem;
  }
  .langIcon {
    display: none;
  }
}

.disabled {
  color: #718096;
}

.disabled:hover {
  cursor: default;
  background-color: transparent;
}